const Input = ({
  value,
  onChange,
  placeholder,
  ...props
               }) => {
  return (
    <input
      className="border border-primary w-full h-[44px] rounded-[8px] outline-none px-[24px] text-[12px] placeholder-[#C2C2C2]"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    />
  );
}

export default Input;
