import classNames from "classnames";

const AgenciesFeaturesBlock = ({
  title,
  text,
  image,
  reverse
                               }) => {
  return (
    <div className={classNames("flex gap-[40px]", {
      "flex-col-reverse sm:flex-row-reverse": reverse,
      "flex-col-reverse sm:flex-row": !reverse
    })}>
      <div className="flex-1 2xl:basis-5/12">
        <h3 className="text-primary text-[23px] font-semibold leading-[140%]">{title}</h3>
        <div className="mt-[12px] text-[#60697B] text-[17px] font-medium leading-[170%]">{text}</div>
      </div>
      <div className="flex-1 2xl:basis-7/12">
        <img src={image} alt="reelty"/>
      </div>
    </div>
  );
}

export default AgenciesFeaturesBlock;
