import { useTranslation } from 'react-i18next';
import RowCard from "../users/RowCard";

const ChooseUsBlock = () => {
  const { t } = useTranslation();
  
  return (
    <div
      className="px-[25px] 2xl:px-[300px] pt-[46px] pb-[20px] sm:pt-[76px] sm:pb-[76px] flex justify-center items-center gap-[40px]">
      <div className="flex-1">
        <div className="text-primary font-semibold tracking-[0.4px] uppercase leading-[135%]">
          {t('choose-us-title')}
        </div>
        <div className="text-[#343F52] mt-[16px] text-[42px] leading-[157.5%] sm:leading-[120%]">
         <span className="text-primary font-semibold">
           Reelty
         </span>
          {" - " + t('choose-us-main-text')}
        </div>
        <div className="text-[#60697b] mt-[26px] text-[17px] leading-[170%]">
          {t('choose-us-description')}
        </div>
      </div>
      
      <div className="hidden md:flex flex-col flex-1 gap-[30px]">
        <div className="pr-[15px]">
          <RowCard
            number={t('choose-us-card-1-number')}
            title={t('choose-us-card-1-title')}
            text={t('choose-us-card-1-text')}
          />
        </div>
        <div className="pl-[40px]">
          <RowCard
            number={t('choose-us-card-2-number')}
            title={t('choose-us-card-2-title')}
            text={t('choose-us-card-2-text')}
          />
        </div>
        <div className="pl-[15px] pr-[15px]">
          <RowCard
            number={t('choose-us-card-3-number')}
            title={t('choose-us-card-3-title')}
            text={t('choose-us-card-3-text')}
          />
        </div>
      </div>
    </div>
  );
}

export default ChooseUsBlock;
