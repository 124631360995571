import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import I18nProvider from "./i18n/I18nProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nProvider>
      <App />
    </I18nProvider>
  </React.StrictMode>
);
