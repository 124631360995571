import { Navigate } from 'react-router-dom';
import LandingLayout from "../pages/Layout";
import UsersPage from "../pages/UsersPage";
import AgenciesPage from "../pages/AgenciesPage";
import NotFoundPage from "../pages/NotFoundPage"

const routes = [
  {
    path: "/",
    element: <Navigate to="/cz" />
  },
  {
    path: "/agencies",
    element: <Navigate to="/agencies/cz" />
  },
  {
    path: "/:lang",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <UsersPage/>,
      },
    ],
  },
  {
    path: "/agencies/:lang",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <AgenciesPage/>,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  }
]

export default routes;
