import { Link, useLocation, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { logEvent } from "firebase/analytics"

import useAnalytics from "../../hooks/useAnalytics"

const SiteSwitcher = ({ className }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const { pathname } = useLocation();

  const isAgency = pathname.startsWith('/agencies/');

  const link = isAgency ? `/${lang}` : `/agencies/${lang}`;
  const text = isAgency ? t("header-for-users") : t("header-for-agency");

  const { analytics } = useAnalytics();

  const handleLinkClick = () => {
    const page = window.location.pathname;
    logEvent(analytics, "switch_page_click", {
      page,
      link,
    });
  };

  return (<Link to={link}>
    <div
      className={className}
      onClick={handleLinkClick}
    >
      {text}
    </div>
  </Link>)
};

export default SiteSwitcher;
