import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {useState} from "react";

const firebaseConfig = {
  apiKey: "AIzaSyCGXNs-l7bykoRRLhJM5R6rWmI3o_qg56E",
  authDomain: "reelty-91bd8.firebaseapp.com",
  projectId: "reelty-91bd8",
  storageBucket: "reelty-91bd8.appspot.com",
  messagingSenderId: "637352652920",
  appId: "1:637352652920:web:1db46c5511f134fdb33d8d",
  measurementId: "G-CRFRHRYKHT"
};

const useAnalytics = () => {
  const app = initializeApp(firebaseConfig);
  const [analytics] = useState(getAnalytics(app));

  return {
    analytics,
  }
}

export default useAnalytics;
