import { useEffect } from "react"
import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "../i18n/I18nProvider";
import { ReactComponent as Logo } from "./../assets/images/logo.svg";
import NotFoundPage from "./NotFoundPage"
import SiteSwitcher from "../components/commons/SiteSwitcher"
// import facebookIcon from './../assets/icons/facebook.svg';
// import instagramIcon from './../assets/icons/instagram.svg';
// import twitterIcon from './../assets/icons/twitter.svg';
// import worldIcon from './../assets/icons/world.svg';
// import youtubeIcon from './../assets/icons/youtube.svg';

const validLanguages = ['en','cz']

const Header = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = useChangeLanguage();

  const getButtonStyle = (targetLang) => {
    return i18n.language === targetLang ? "font-bold" : "font-normal";
  };

  return (
    <header className="flex justify-between items-center bg-primary h-[86px] pl-[32px] pr-[16px] md:px-[30px] 2xl:px-[300px]">
      <div className="w-[137px] h-[47px]">
        <Logo />
      </div>
      <div className="flex items-center gap-[32px] text-[17px]">
        <div className="items-center gap-[12px] text-white hidden md:flex">
          <button
            className={getButtonStyle("cz")}
            onClick={() => changeLanguage("cz")}
          >
            {t("header-change-lang-cz")}
          </button>
          <button
            className={getButtonStyle("en")}
            onClick={() => changeLanguage("en")}
          >
            {t("header-change-lang-en")}
          </button>
        </div>
        <SiteSwitcher className="text-primary bg-white w-[148px] h-[46px] flex-center rounded-[8px]" />
      </div>
    </header>
  );
};

const Footer = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = useChangeLanguage();

  const getButtonStyle = (targetLang) => {
    return i18n.language === targetLang ? "font-bold" : "font-normal";
  };

  return (
    <footer className="md:min-w-[510px] bg-primary text-white pt-[64px] md:pt-[52px] pr-[24px] 2xl:pr-[190px] pb-[80px] md:pb-[110px] pl-[24px] 2xl:pl-[190px] ">
      <div className="text-[42px] sm:text-center md:text-left font-bold leading-[120%] ">
        {t("footer-available-soon")}
      </div>
      <div className="w-full h-[2px] mt-[27px] bg-white hidden md:block" />
      <div className="hidden md:grid grid-cols-[auto_auto_auto] gap-y-[40px] justify-between items-center mt-[60px] text-[17px]">
        <div className="w-full flex justify-center items-center">
          <Logo />
        </div>
        <div className="w-full flex justify-center items-center text-[20px] font-semibold whitespace-nowrap">
          {t("footer-get-in-touch")}
        </div>
        <div className="w-full flex justify-center items-center text-[20px] font-bold min-w-[150px] whitespace-nowrap">
          {t("footer-learn-more")}
        </div>
        <div className="w-full flex justify-center items-center text-grey">
          {t("footer-rights")}
        </div>
        <div className="w-full flex justify-center items-center max-w-[186px] text-center text-grey">{t("footer-address")}</div>
        <SiteSwitcher className="flex justify-center items-center text-grey whitespace-nowrap" />
        <div />
        <a href="mailto:info@konspirecreative.cz">
          <div className="flex justify-center items-center">{t("footer-email")}</div>
        </a>
        <div />
      </div>

      <div className="flex md:hidden flex-col justify-between mt-[138px] text-[17px] items-center">
        <div className="flex flex-col justify-center items-center text-[17px]">
          <div className="w-full flex justify-center items-center">
            <Logo />
          </div>
          <div className="text-grey mt-[46px] text-center">
            {t("footer-rights")}
          </div>
        </div>

        <div className="flex justify-center items-center h-full mt-[60px]">
          <div className="text-center">
            <div className="mt-[30px] text-[20px] font-semibold">
              {t("footer-get-in-touch")}
            </div>
            <div className="mt-[32px] max-w-[186px] text-grey">{t("footer-address")}</div>
            <a href="mailto:info@konspirecreative.cz">
              <div className="mt-[78px]">{t("footer-email")}</div>
            </a>
          </div>
        </div>

        <div className="flex items-center text-white gap-[20px] mt-[24px]">
          <button
            className={getButtonStyle("cz")}
            onClick={() => changeLanguage("cz")}
          >
            {t("header-change-lang-cz")}
          </button>
          <button
            className={getButtonStyle("en")}
            onClick={() => changeLanguage("en")}
          >
            {t("header-change-lang-en")}
          </button>
        </div>
      </div>
    </footer>
  );
};

const LandingLayout = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    if(i18n.language !== lang) {
      i18n.changeLanguage(lang)
    }
  })

  if (!validLanguages.includes(lang)) {
    return <NotFoundPage />
  }

  return (
    <div className="w-screen h-screen overflow-auto">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default LandingLayout;
