/* global gtag */

import { useTranslation } from "react-i18next";
import headerImage from "../assets/images/agencies/header.png";
import body1Image from "../assets/images/agencies/body1.png";
import body2Image from "../assets/images/agencies/body2.png";
import body3Image from "../assets/images/agencies/body3.png";
import ChooseUsBlock from "../components/commons/ChooseUsBlock";
import AgenciesFeaturesBlock from "../components/agencies/AgenciesFeaturesBlock";
import Input from "../components/commons/Input";
import { useState } from "react";
import successEmailIcon from "../assets/icons/successEmail.svg";
import { postLead } from "../requests/request";
import useAnalytics from "../hooks/useAnalytics";
import { logEvent } from "firebase/analytics"

const AgenciesPage = () => {
  const [email, setEmail] = useState("");
  const [emailIsSended, setEmailIsSended] = useState(false);
  const [sendButtonIsActive, setSendButtonIsActive] = useState(false);
  const handleChangeEmail = (e) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setSendButtonIsActive(emailRegex.test(emailInput));
  };
  const { t } = useTranslation();
  const {analytics} = useAnalytics();

  function gtag_report_conversion(value = 1.0, currency = 'CZK') {
    gtag('event', 'conversion', {
      'send_to': 'AW-16538271966/x7VVCPu32cYZEN6BiM49',
      'value': value,
      'currency': currency,
    });
    return false;
  }

  const handleSendClick = async () => {
    const page = window.location.pathname;

    await postLead({
      page,
      email,
    });
    setEmailIsSended(true);

    gtag_report_conversion();
    logEvent(analytics, 'send_email_agencies', {
      page,
      email,
    });
  };

  return (
    <div>
      <div className="min-h-screen flex flex-col 2xl:pl-[300px] 2xl:pr-[115px] px-[32px] pb-[54px]  justify-center items-center bg-primary text-white flex-col-reverse sm:flex-row">
        <div className="flex-1 pt-[33px] fade-in">
          <h1 className="text-[46px] font-bold leading-[110%]">
            {t('agent-header-title')}
            <br/>
            {t('agent-header-second-title')}
          </h1>
          <h2 className="text-[26px] mt-[16px]">{t('agent-header-description')}</h2>
        </div>
        <div className="flex-1 fade-in">
          <img src={headerImage} alt="reelty"/>
        </div>
      </div>

      <ChooseUsBlock/>

      <div className="px-[32px] 2xl:px-[280px] pb-[62px] pt-[40px] bg-[#f8fbff] flex flex-col items-center">
        <div className="text-primary font-semibold tracking-[0.4px] uppercase leading-[135%]">
          {t('agent-our-features-title')}
        </div>
        <h2 className="text-center text-[42px] font-semibold leading-[120%] mt-[16px]">
          {t('agent-our-features-description')}
        </h2>
        <div className="flex flex-col gap-[92px] mt-[42px]">
          <AgenciesFeaturesBlock
            title={t('agent-features-1')}
            text={t('agent-features-1-description')}
            image={body1Image}
            reverse={false}
          />
          <AgenciesFeaturesBlock
            title={t('agent-features-2')}
            text={t('agent-features-2-description')}
            image={body2Image}
            reverse={true}
          />
          <AgenciesFeaturesBlock
            title={t('agent-features-3')}
            text={t('agent-features-3-description')}
            image={body3Image}
            reverse={false}
          />
        </div>
      </div>

      <div className="py-[105px] flex-center px-[32px] 2xl:px-[300px]">
        <div className="w-[700px]">
          <h2 className="text-[#343F52] text-[32px] font-semibold leading-[140%] text-center">
            {t('agent-launching-soon')}
          </h2>
          <div className="mt-[24px] text-[#60697B] text-[17px] font-medium leading-[170%]">
            {t('agent-email-invitation')}
          </div>
          {!emailIsSended ? (
            <div className="mt-[24px]">
              <div className="flex gap-[15px]">
                <div className="flex-1">
                  <Input
                    value={email}
                    onChange={handleChangeEmail}
                    placeholder={t('agent-enter-email')}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    disabled={!sendButtonIsActive}
                    className={sendButtonIsActive ? 'button-active' : 'button-inactive'}
                    onClick={handleSendClick}
                  >
                    {t('agent-send-button')}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-[24px]">
              <div className="flex gap-[12px] items-center">
                <img src={successEmailIcon} alt="reelty" />
                <div className="text-[#60697B] text-[17px] font-medium leading-[170%]">
                  {t('agent-thank-you')}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AgenciesPage;
