// I18nProvider.js
import React from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom"
import {I18nextProvider, useTranslation} from 'react-i18next';
import i18n from './i18n';

const I18nProvider = ({ children }) => (
	<I18nextProvider i18n={i18n}>
		{children}
	</I18nextProvider>
);

export default I18nProvider;

export const useChangeLanguage = () => {
	const { i18n } = useTranslation();
	const { lang } = useParams();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	return (language) => {
		const regex = new RegExp(`/${lang}(?=/|$)`, 'i');
		const newLink = pathname.replace(regex, `/${language}`)
		i18n.changeLanguage(language).then(() => {
			navigate(newLink)
		});
	};
};
