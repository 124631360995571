/* global gtag */

import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import headerImageEN from "../assets/images/users/header_en.png";
import headerImageCZ from "../assets/images/users/header_cz.png"
import body1ImageEN from "../assets/images/users/body1_en.png";
import body1ImageCZ from "../assets/images/users/body1_cz.png";
import body2ImageEN from "../assets/images/users/body2_en.png";
import body2ImageCZ from "../assets/images/users/body2_cz.png";
import body3ImageEN from "../assets/images/users/body3_en.png";
import body3ImageCZ from "../assets/images/users/body3_cz.png";

import ServiceCard from "../components/users/ServiceCard";
import ChooseUsBlock from "../components/commons/ChooseUsBlock";
import Input from "../components/commons/Input";
import { useState } from "react";
import successEmailIcon from "../assets/icons/successEmail.svg";
import {postLead} from "../requests/request";
import {logEvent} from "firebase/analytics";
import useAnalytics from "../hooks/useAnalytics";

const images = {
  cz: {
    header: headerImageCZ,
    map: body1ImageCZ,
    favorites: body2ImageCZ,
    filters: body3ImageCZ,
  },
  en: {
    header: headerImageEN,
    map: body1ImageEN,
    favorites: body2ImageEN,
    filters: body3ImageEN,
  }
}

const UsersPage = () => {
  const [email, setEmail] = useState("");
  const [emailIsSended, setEmailIsSended] = useState(false);
  const { t } = useTranslation();
  const {analytics} = useAnalytics();
  const { lang } = useParams()

  const [sendButtonIsActive, setSendButtonIsActive] = useState(false);

  const handleChangeEmail = (e) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setSendButtonIsActive(emailRegex.test(emailInput));
  };
  
  function gtag_report_conversion(value = 1.0, currency = 'CZK') {
    gtag('event', 'conversion', {
      'send_to': 'AW-16538271966/x7VVCPu32cYZEN6BiM49',
      'value': value,
      'currency': currency,
    });
    return false;
  }

  const handleSendClick = async (e) => {
    e.stopPropagation();
    const page = window.location.pathname;
    await postLead({
      page,
      email,
    });
    setEmailIsSended(true);

    gtag_report_conversion();
    logEvent(analytics, 'send_email_users', {
      page,
      email,
    });
  };

  return (
    <div>
      <div className=" min-h-screen flex flex-col px-[32px] 2xl:px-[300px] pb-[86px] flex justify-center items-center bg-primary text-white flex-col-reverse sm:flex-row">
        <div className="flex-1 pt-[33px] fade-in">
          <h1 className="text-[46px] font-bold leading-[110%]">
            {t('users-header-title')}
            <br/>
            {t('users-header-second-title')}
          </h1>
          <h2 className="text-[26px] mt-[16px]">{t('users-header-description')}</h2>
        </div>
        <div className="flex-1 fade-in">
          <img src={images[lang].header} alt="reelty"/>
        </div>
      </div>

      <ChooseUsBlock />

      <div className="px-[32px] 2xl:px-[300px] pb-[54px] pt-[40px] sm:bg-[#f8fbff] flex flex-col items-center">
        <div className="text-primary font-semibold tracking-[0.4px] uppercase leading-[135%]">
          {t('users-features-title')}
        </div>
        <h2 className="text-center text-[42px] font-semibold leading-[120%] mt-[16px]">
          {t('users-features-description')}
        </h2>
        <div className="flex justify-between mt-[52px] flex-col sm:flex-row gap-[34px]">
          <ServiceCard
            image={images[lang].map}
            title={t('service-title-1')}
            text={t('service-description-1')}
          />
          <ServiceCard
            image={images[lang].favorites}
            title={t('service-title-2')}
            text={t('service-description-2')}
          />
          <ServiceCard
            image={images[lang].filters}
            title={t('service-title-3')}
            text={t('service-description-3')}
          />
        </div>
      </div>

      <div className="pt-[70px] pb-[70px] flex-center px-[32px] 2xl:px-[300px] ">
        <div className="w-[750px] ">
          <h2 className="text-[#343F52] text-[32px] font-semibold text-center">
            {t('users-invite-title')}
          </h2>
          <div className="mt-[24px] text-[#60697B] text-[17px] font-medium leading-[170%]">
            {t('users-invite-description')}
          </div>
          {!emailIsSended ? (
            <div className="mt-[24px]">
              <div className="flex gap-[15px]">
                <div className="flex-1">
                  <Input
                    value={email}
                    onChange={handleChangeEmail}
                    placeholder={t('users-email-placeholder')}
                  />
                </div>
                <div>
                  <button
                    disabled={!sendButtonIsActive}
                    className={sendButtonIsActive ? 'button-active' : 'button-inactive'}
                    onClick={handleSendClick}
                  >
                    {t('users-send-button')}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-[24px]">
              <div className="flex gap-[12px] items-center">
                <img src={successEmailIcon} alt="reelty" />
                <div className="text-[#60697B] text-[17px] font-medium leading-[170%]">
                  {t('users-thank-you')}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UsersPage;
